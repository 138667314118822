const destinationSubpath = '/destinations'
const routeSubpath = '/route'

export const planRoutes = [
  {
    path: `${destinationSubpath}`,
    name: 'Destinations',
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '@/views/destinations/Index.vue'
      )
  },
  {
    path: `${destinationSubpath}/:slug`,
    name: 'Destination',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '@/views/destinations/Show.vue'
      )
  },
  {
    path: `${routeSubpath}/:routeName`,
    name: 'RouteDescription',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '@/views/destinations/RouteDescription.vue'
      )
  },
  {
    path: '/routes-and-timetables',
    name: 'Routes',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '@/views/Routes.vue'
      )
  }
]
