import { camelizeKeys } from '@/camelize'
import axios from 'axios'
import responseTransformer from './responseTransformer'

const projectId = process.env.VUE_APP_PROJECT_ID
const baseURL =
  process.env.VUE_APP_KENTICO_ENV === 'preview'
    ? `https://preview-deliver.kontent.ai/${projectId}/items`
    : `https://deliver.kontent.ai/${projectId}/items`
const Authorization = process.env.VUE_APP_PREVIEW_API_KEY
  ? `Bearer ${process.env.VUE_APP_PREVIEW_API_KEY}`
  : null

const httpClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization
  }
})

httpClient.interceptors.response.use(responseTransformer)
httpClient.interceptors.response.use(response => camelizeKeys(response))

export default httpClient
