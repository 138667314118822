import httpClient from './httpClient'
import { Image } from '@/models/Image'
import { AxiosResponse } from 'axios'
import { Metadata } from '@/models/Metadata'

export interface Page extends Metadata{
  title: string;
  subtitle: string;
  path: string;
  image?: Image;
}

interface Submenu {
  title: string;
  path: string;
  pages: Page[];
}

const codenames = ['plan_submenu', 'company_submenu', 'services_submenu']

const getSubmenus = () =>
  httpClient.get<{ [index: string]: Submenu }>('', {
    params: {
      'system.type': 'submenu',
      'system.codename[in]': codenames.join(','),
      elements: 'pages,path,title'
    }
  })

const getPage = (slug: string): Promise<AxiosResponse<Page>> =>
  httpClient.get<Page>('', {
    params: {
      'system.type': 'page',
      'elements.path': slug
    }
  })

const getPageCustom = (slug: string): Promise<AxiosResponse<Page>> =>
  httpClient.get<Page>('', {
    params: {
      'system.type': 'page_ckeditor',
      'elements.path': slug
    }
  })

export { getSubmenus, getPage, getPageCustom }
