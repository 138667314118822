import { camelize } from '@/camelize'
import { AxiosResponse } from 'axios'
import { ImageItem, Item, Element } from '.'

let linkedItems: { [x: string]: Item }
const itemMap = new Map()
const memoizeItem = (codename: string, generateItem: () => Record<string, unknown>) => {
  if (itemMap.has(codename)) return itemMap.get(codename)

  itemMap.set(codename, generateItem())
  return itemMap.get(codename)
}

const transformDefaultItem = (
  { elements }: Item,
  nestingDenylist?: string[],
  treatAsArray?: string[]
) => Object.keys(elements).reduce((elementObj, key) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  elementObj[key] = transformElement(elements[key], nestingDenylist, treatAsArray)
  return elementObj
}, {} as { [x: string]: unknown })

const transformImageItem = ({
  elements: {
    image: {
      value: [image]
    },
    alternate_text: { value: altText }
  }
}: ImageItem) => ({ ...image, altText })

const transformItems = (items: Item[], nestingDenylist?: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (items.length === 1) return transformItem(items[0], nestingDenylist)

  return items.reduce((itemsHash: { [index: string]: Item }, item: Item) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    itemsHash[item.system.codename] = transformItem(item, nestingDenylist)
    return itemsHash
  }, {})
}

const transformItem = (item: Item, nestingDenylist?: string[]) => {
  if (!item) return

  const {
    system: { codename, type }
  } = item
  // eslint-disable-next-line complexity
  return memoizeItem(codename, () => {
    switch (type) {
      case 'image':
        return transformImageItem(item as ImageItem)
        break
      case 'destination':
        return transformDefaultItem(item, ['Explore Destinations'], ['Routes To'])
        break
      case 'routes_page':
        return transformDefaultItem(item, [], ['routes list'])
        break
      case 'route_description':
        return transformDefaultItem(item, [], ['More routes', 'timetables'])
        break
      default:
        return transformDefaultItem(item, nestingDenylist)
        break
    }
  })
}
// eslint-disable-next-line complexity
const transformElement = (
  element: Element,
  nestingDenylist?: string[],
  treatAsArray?: string[]
) => {
  if (element.type === 'modular_content') {
    if (treatAsArray?.includes(element.name)) {
      return element.value.map(key => ({
        key: camelize(key),
        ...transformItem(linkedItems[key], nestingDenylist)
      }))
    } else if (nestingDenylist?.includes(element.name)) {
      return element.value.map(key => camelize(key))
    }
    if (element.value.length === 1) {
      return transformItem(linkedItems[element.value[0]], nestingDenylist)
    }

    return element.value.reduce((item: { [x: string]: unknown }, itemKey) => {
      item[linkedItems[itemKey].system.codename] = transformItem(
        linkedItems[itemKey],
        nestingDenylist
      )
      return item
    }, {})
  } else return element.value
}

const linkedDestinations = () => {
  const filteredItems = Object.values(linkedItems).filter(
    item => item.system.type === 'destination'
  )
  if (filteredItems.length === 0) return null

  return {
    linkedDestinations: transformItems(filteredItems, ['Explore Destinations'])
  }
}

export default function responseTransformer (
  response: AxiosResponse
): AxiosResponse {
  const {
    data: { item, items, modular_content: modularContent }
  } = response
  itemMap.clear()
  linkedItems = modularContent
  if (item) {
    return {
      ...response,
      data: { ...transformItem(item), ...linkedDestinations() }
    }
  }

  return {
    ...response,
    data: { ...transformItems(items), ...linkedDestinations() }
  }
}
