
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Navbars/Footer.vue'
import Snackbar from '@/components/Snackbar.vue'
import useSnackbar from '@/composables/useSnackbar'
import '@/assets/styles/colors.scss'
import '@/assets/styles/typography.scss'
import '@/assets/styles/widgets.scss'
import '@/assets/styles/inputs.scss'
import '@/assets/styles/cms_content.scss'
import { version } from '../package.json'
import useTitle from '@/composables/useTitle'
import { useHead } from '@vueuse/head'

export default defineComponent({
  components: { Header, Footer, Snackbar },
  setup () {
    const { title } = useTitle()
    // eslint-disable-next-line max-len
    const content = 'Expressway, Bus Éireann’s premium coach service, provides direct and regular services on 19 routes throughout Ireland.'
    useHead({
      meta: [
        {
          name: 'og:description',
          content: content,
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: 'Expressway - Way Better',
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: process.env.VUE_APP_SITE_URL,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`,
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: 'en_IE',
          key: 'ogLocale'
        }
      ]
    })

    return { ...useSnackbar(), version, title }
  }
})
