import { ref, readonly, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

interface SnackbarState {
  message: string;
  type?: 'danger' | 'success';
}

const snackbarState = ref<SnackbarState>()
export const setSnackbar = (message: string, type?: 'danger' | 'success') => {
  snackbarState.value = { message, type }
}

const closeSnackbar = () => { snackbarState.value = undefined }

export default function useSnackbar () {
  const router = useRouter()

  router.beforeEach(() => closeSnackbar())

  watchEffect(() => {
    if (!snackbarState.value) return

    document.getElementById('snackbar')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  })

  return {
    setSnackbar,
    closeSnackbar,
    snackbarState: readonly(snackbarState)
  }
}
